@import "~material-design-icons/iconfont/material-icons";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import "./colours";

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "neutral": $neutral,
  "white": #fff,
  "danger": red,
);

@import "bootstrap";

@import "./styles/form";
@import "./styles/button";
@import "./styles/fonts";

body {
  font-family: SourceSansPro;

  p {
    font-size: 1.1rem;
  }
}
