$radio-size: 1.5rem;

$grey: #cecece;
.radioGroup {
  display: flex;
  align-items: center;
  font-size: 1.1rem;

  .radioButton {
    min-width: $radio-size;
    min-height: $radio-size;
    border-radius: 50%;
    border: 1px solid black;
    background: linear-gradient(
      $grey,
      lighten($grey, 10%),
      lighten($grey, 12%),
      lighten($grey, 14%),
      lighten($grey, 16%)
    );
    margin-right: 0.5rem;
    display: grid;
    place-items: center;

    &::before {
      content: "";
      height: 50%;
      width: 50%;
      background-color: black;
      border-radius: 50%;
      transition: transform 0.2s ease-in-out;
      transform: scale(0);
    }
    &.selected::before {
      transform: scale(1);
    }
  }
}
