@import "../../colours";

@keyframes loadingSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingSpinner {
  display: inline-block;
  &:after {
    content: " ";
    display: block;
    width: 1em;
    height: 1em;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid $primary;
    border-color: $primary transparent $primary transparent;
    animation: loadingSpin 1.2s linear infinite;
  }
}
