@import "../../../colours";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/_breakpoints";

.progress-bar-wrapper {
  @include media-breakpoint-down(md) {
    transform: translateX(15vw);
  }

  .progress-bar {
    .item-wrapper:not(:last-child)::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 3rem;
      height: 2px;
      width: calc(100% - 3rem);
      background-color: lightgrey;
    }

    .item-wrapper {
      position: relative;
      display: flex;
      .item {
        position: relative;

        color: grey;
        font-size: 1.5rem;
        width: 3rem;
        border: 1px solid grey;
        border-radius: 50%;
        height: 3rem;

        &.active {
          background-color: $red;
          border-color: $red;
          color: white;
        }

        &.completed {
          background-color: white;
          color: $green;
          border-color: $green;

          .icon {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
