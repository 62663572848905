$width: 60px;

.title {
  position: relative;
  font-size: 2.2rem;
  margin-bottom: 2rem;

  &::after {
    content: "";
    background-color: #f8a71e;
    width: $width;
    height: 5px;
    position: absolute;
    bottom: -25px;
    right: calc(50% - 30px);
  }
}
