.title {
  letter-spacing: 3px;
  font-weight: bold;
}

.disclaimer {
  p {
    font-size: 0.9rem;
  }
}

.contactConsent {
  background-color: #eef8fd;
  padding: 1rem;
  border: 1px solid #e0f1fa;
}
