@import "../colours";

form {
  label {
    margin-bottom: 0.5rem;
  }
  input,
  select {
    border-color: $input-border !important;
  }

  .form-error {
    color: $red;
  }
}
